import React from "react";
import "./Home.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation("global");
  return (
    <motion.div
      className="Homes"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 3 }}
    >
      <div className="Home">
        <div className="prueba1"></div>
        <div className="card">
          <div className="content">
            <div className="front">
              <h3 className="title">Gabriel Medina</h3>
              <p className="subtitle"> {t("dev.dev")}</p>
            </div>
            <div className="back">
              <p className="description">gabrielmedinatorres@hotmail.com</p>
              <p className="description">
                <a href="https://github.com/GabrielMedinaT">Github</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
