import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./NavBar.css";

const NavBar = () => {
  const { t, i18n } = useTranslation("global");

  return (
    <div className="navBar">
      <ul className="listaDeLink">
        <li>
          <Link className="enlaces" to="/">
            {t("inicio.inicio")}
          </Link>
          <Link className="enlaces" to="/experiencia">
            {t("exp.experiencia")}
          </Link>
          {/* <Link className="enlaces" to="/portfolio">
            Porfolio{" "}
          </Link> */}
          <Link className="enlaces" to="/formacion">
            {t("edu.form")}
          </Link>
          <Link className="enlaces" to="/contacto">
            {t("cont.cont")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;
