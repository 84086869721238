import React, { useEffect } from "react";
import "./Experiencia.css";
import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const Experiencia = () => {
  const { t } = useTranslation("global");
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="Experiencia">
      <motion.div
        className="Experiencia"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        {" "}
        <div className="imagenFondo"></div>
      </motion.div>
      <div className="primera" id="primera" data-aos="fade-right">
        <h1>2021-2022</h1>
        <h3>{t("it.it")}</h3>
      </div>
      <div className="primera2" id="primera2" data-aos="fade-left">
        <p>{t("it1.it1")}</p>
        <br />
        <p>{t("it2.it2")}</p>
        <br />
        <p>{t("it3.it3")} </p>
      </div>

      {/* <div className="animation" data-aos="fade-left"></div> */}
      <div className="animation" id="segunda2" data-aos="fade-left">
        <h1>2010-2021</h1>
        <h3>{t("it.it")}</h3>
      </div>
      <div className="animation" id="segunda" data-aos="fade-right">
        <p>{t("agriexp.agriexp")}</p>
        <br />
        <p>{t("pisiexp.pisiexp")}</p>
      </div>

      <div>
        <div className="animation" id="tercera" data-aos="fade-right">
          <h1>1999 - 2010</h1>
          <h3>{t("experiencia.administrativo")}</h3>
          <h3>{t("tel.tel")}</h3>
        </div>
        <div className="animation" id="tercera2" data-aos="fade-left">
          <p>{t("admdef.adm")}</p>
          <p>{t("ocga.ocga")}</p>
        </div>
        <span className="division" id="division">
          <span className="division2" id="division2"></span>
          <span className="division3"></span>
          <span className="division4"></span>
        </span>

        <footer></footer>
      </div>
      <div className="contacto">
        <a
          href="https://wa.me/34675489388"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="social">Whatsapp</button>
        </a>
        <a
          href="mailto:gabrielmedinatorres@hotmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="social">Email</button>
        </a>
        <a href="tel:+34675489388" target="_blank" rel="noopener noreferrer">
          <button className="social">{t("telefono.telefono")}</button>
        </a>
      </div>
    </div>
  );
};

export default Experiencia;
