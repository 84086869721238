import React from "react";
import "./Formacion.css";
import { motion } from "framer-motion";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
const Formacion = () => {
  const { t } = useTranslation("global");
  const items = [
    {
      id: 1,
      super: t("super.super"),
      title: t("segu.segu"),
      subtitle: t("certi.certi"),
      detalles: t("segu1.segu1"),
    },
    {
      id: 2,
      super: t("super.super"),
      title: t("poo.poo"),
      subtitle: t("certi.certi"),
      detalles: t("poo2.poo2"),
    },
    {
      id: 3,
      super: t("super.super"),
      title: t("daw.daw"),
      subtitle: t("certi.certi"),
      detalles: t("daw2.daw2"),
    },
    {
      id: 4,
      super: t("super.super"),
      title: t("acce.acce"),
      subtitle: t("grado.grado"),
      detalles: "ULPGC",
    },
    {
      id: 5,
      super: t("super.super"),
      title: t("idio.idio"),
      subtitle: "B1",
      detalles: "",
    },
  ];

  const [selectedId, setSelectedId] = useState(null);
  return (
    <motion.div
      className="Formacion"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      {items.map((item) => (
        <motion.div
          className="primeraformacion"
          key={item.id}
          layoutId={item.id}
          onClick={() => setSelectedId(item.id)}
          style={{ cursor: "pointer" }}
        >
          <motion.h4 className="detalles1">{item.detalles}</motion.h4>
          <motion.h5 className="titulo5">{item.subtitle}</motion.h5>
          <motion.h3 className="tituloh3">{item.title}</motion.h3>
          <motion.h4 className="super">{item.super}</motion.h4>
        </motion.div>
      ))}

      <AnimatePresence>
        {selectedId !== null && (
          <motion.div
            className="modal"
            key={selectedId}
            layoutId={selectedId}
            onClick={() => setSelectedId(null)}
            style={{ cursor: "pointer" }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <motion.h5>{items[selectedId - 1].subtitle}</motion.h5>
            <motion.h2>{items[selectedId - 1].title}</motion.h2>
            <motion.h4>{items[selectedId - 1].detalles}</motion.h4>
            <motion.button onClick={() => setSelectedId(null)}>
              {t("cerrar.cerrar")}
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Formacion;
