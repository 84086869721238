import React from "react";
import "./Contacto.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import emailJS from "emailjs-com";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation("global");
  const [nombre, setNombre] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const navigate = useNavigate();

  function enviarEmail(e) {
    e.preventDefault();
    emailJS
      .sendForm(
        "service_vhoucq7",
        "template_lik2icb",
        e.target,
        "pDszFI-95s8V46sX2"
      )
      .then(
        (result) => {
          console.log(result.text);
          navigate("/Gracias");
        },
        (error) => {
          console.log(error.text);
        }
      );
    setEmail("");
    setNombre("");
    setMensaje("");
  }

  return (
    <motion.div
      className="Contacto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="imagenfondo"></div>

      <form action="" className="formulario" onSubmit={enviarEmail}>
        <input
          type="text"
          name="nombre"
          id="nombre"
          placeholder={t("nombre.nombre")}
          maxLength={10}
          required
          onChange={(e) => setNombre(e.target.value)}
          value={nombre}
        />
        <input
          type="text"
          name="email"
          id="email"
          placeholder={t("email.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <textarea
          className="mensaje"
          type="textarea"
          name="mensaje"
          id="mensaje"
          placeholder={t("mensaje.mensaje")}
          row="10"
          column="10"
          value={mensaje}
          onChange={(e) => setMensaje(e.target.value)}
        />
        <button className="continue-application">
          <div>
            <div className="pencil"></div>
            <div className="folder">
              <div class="top">
                <svg viewBox="0 0 24 27">
                  <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                </svg>
              </div>
              <div className="paper"></div>
            </div>
          </div>
          {t("enviar.enviar")}
        </button>
      </form>
    </motion.div>
  );
}

export default Contact;
