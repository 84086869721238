import React from "react";
import "./Gracias.css";
import { useNavigate } from "react-router-dom";

const Gracias = () => {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate("/");
  }, 4000);

  return <div className="imagenGracias">Gracias</div>;
};

export default Gracias;
