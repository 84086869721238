import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NavBar from "./componets/NavBar";
import Home from "./pages/Home";
import Experiencia from "./pages/Experiencia";
import Formacion from "./pages/Formacion";
import Contacto from "./pages/Contacto";
import Gracias from "./pages/Gracias";
import { motion } from "framer-motion";
import Portfolio from "./pages/Portfolio";

function App() {
  return (
    <motion.div
      className="App"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <div className="App">
        <div className="imagenFondoprincipal"></div>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/Experiencia" element={<Experiencia />}></Route>
            <Route path="/Formacion" element={<Formacion />}></Route>
            {/* <Route path="/Portfolio" element={<Portfolio />}></Route> */}
            <Route path="/Contacto" element={<Contacto />}></Route>
            <Route path="Gracias" element={<Gracias />}></Route>
          </Routes>
        </Router>
      </div>
    </motion.div>
  );
}

export default App;
